import React, { createContext, useState, useMemo, FC, ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IUserProps, User } from '../common/data/IUserProps';
import { TOKEN_KEY, UNIDADE_KEY, USER_KEY } from './contextKeys';
import contentRoutes, { RoutesProps, filterRoutesByRole } from '../routes/contentRoutes';
import { IUnidadeProps } from '../common/data/IUnidadeProps';
import jwt from 'jwt-simple';
import moment from 'moment';

export interface IAuthContextProps {
	token: string;
	setToken: (value: ((prevState: any) => any) | any) => void;
	userData: IUserProps;
	setUserData: (value: ((prevState: any) => any) | any) => void;
	unidade: any;
	setUnidade: (value: ((prevState: any) => any) | any) => void;
	logout: () => void;
	getUserData: () => User;
	getUnidadeData: () => any;
	getUserRoutes: () => RoutesProps[];
	handleOpenSupport: () => any;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

const SECRET_KEY = process.env.REACT_JWT_SECRET || '';
function generateToken(unidade_id:number, product_name:string, user_email:string) {
	const payload = {
	  unidade_id: unidade_id,
	  product_name: product_name,
	  user_email: user_email,
	  exp: moment().add(24, 'hour').unix()
	};
	return jwt.encode(payload, SECRET_KEY);
  }

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [userData, setUserData] = useState<User>();
	const [token, setToken] = useState(null);
	const [unidade, setUnidade] = useState<IUnidadeProps>();

	const handleOpenSupport = () => {
		if(unidade?.id && userData?.email) {
			const token = generateToken(unidade?.id, 'cp', userData?.email);
			window.open(`https://backoffice.kpih.com.br/help/docs/cp?token=${token}`, '_blank');
		}
	}

	const logout = () => {
		setToken(null)
		setUserData(undefined)
		setUnidade(undefined)
		localStorage.clear();
	}

	useEffect(() => {
		if(userData) {
			localStorage.setItem(USER_KEY, JSON.stringify(userData))
		}
	}, [userData])

	useEffect(() => {
		if(token) {
			localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
		}
	}, [token]);

	useEffect(() => {
		if(unidade) {
			localStorage.setItem(UNIDADE_KEY, JSON.stringify(unidade));
		}
	}, [unidade]);

	useEffect(() => {
		const loadStoragedData = async () => {
			const storedToken = localStorage.getItem(TOKEN_KEY);
			let doLogout = false;

			if (storedToken) {
				const localToken = JSON.parse(storedToken);
				setToken(localToken);
			} else {
				logout();
			}

			const storedUser = localStorage.getItem(USER_KEY);

			if (storedUser) {
				const localUser = JSON.parse(storedUser);
				setUserData(new User(localUser));
			} else {
				logout();
			}

			const storedUnidade = localStorage.getItem(UNIDADE_KEY);

			if (storedUnidade) {
				const localUnidade = JSON.parse(storedUnidade);
				setUnidade(localUnidade);
			} else {
				// logout();
			}

			if (doLogout) {
				logout();
			}

		};
		loadStoragedData();
	}, []);

	// LOAD USER DATA
	useEffect(() => {
		if(!token || !unidade) return;
		const loadStoragedData = async () => {
			getUserData();
		};
		loadStoragedData();
	}, []);

	function getUnidadeData() {
		const storedUnidadeData = localStorage.getItem(UNIDADE_KEY);

		if (storedUnidadeData) {
			let data = JSON.parse(storedUnidadeData);
			return data;
		}
		return null;
	}

	function getUserData() {
		const storedUserData = localStorage.getItem(USER_KEY);

		if (storedUserData) {
			let data = JSON.parse(storedUserData);
			setUserData(data);
			return new User(data);
		}
	}

	function getUserRoutes() {
		const storedUserData = localStorage.getItem(USER_KEY);

		if (storedUserData) {
			let userData = JSON.parse(storedUserData);
			return filterRoutesByRole(userData?.role);
		}
		return contentRoutes.filter(p => p.path == '/login' || p.path == '/logout' || p.path == '/404' || p.path == '/')
	}

	// @ts-ignore
	const values: IAuthContextProps = useMemo(
		() => ({
			userData,
			setUserData,
			token,
			setToken,
			unidade,
			setUnidade,
			logout,
			getUserData,
			getUnidadeData,
			getUserRoutes,
			handleOpenSupport,
		}),
		[
			userData,
			setUserData,
			token,
			setToken,
			unidade,
			setUnidade,
			logout,
			getUserData,
			getUnidadeData,
			getUserRoutes,
			handleOpenSupport,
		],
	);

	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;

import React, { createContext, useState, useMemo, FC, ReactNode, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import apiRb from '../services/apiRb';
import AuthContext from './authContext';
import { CIDS_KEY, CONVENIOS_KEY, DRGS_KEY, ESPECIALIDADES_KEY, MEDICOS_KEY,
	PROCEDIMENTOS_CIRURGICOS_KEY, PROCEDIMENTOS_KEY, TIPOS_ORIGEM_PACIENTE_KEY } from './contextKeys';
import { IUserProps, User } from '../common/data/IUserProps';

export interface ILocalContextProps {
	convenios: string[];
	procedimentos: { id: number, CD_PRO_FAT: string, nome: string }[];
	procedimentosCirurgicos: { codigo: string, descricao: string }[];
	setores: string[];
	especialidades: string[];
	drgs: string[];
	medicos: { id: number, crm: string, nome: string }[];
	tiposOrigemPaciente: string[];
	cids: { id: number, codigo: string, descricao: string }[];
	userData: IUserProps;
	loadContext: () => void;
}
const LocalContext = createContext<ILocalContextProps>({} as ILocalContextProps);

interface ILocalContextProviderProps {
	children: ReactNode;
}
export const LocalContextProvider: FC<ILocalContextProviderProps> = ({ children }) => {
	const { token, unidade } = useContext(AuthContext);
	const [load, setLoad] = useState(true);
	const [convenios, setConvenios] = useState<string[]>();
	const [especialidades, setEspecialidades] = useState<string[]>();
	const [procedimentos, setProcedimentos] = useState<{ id: number, CD_PRO_FAT: string, nome: string }[]>();
	const [procedimentosCirurgicos, setProcedimentosCirurgicos] = useState<{ codigo: string, descricao: string }[]>();
	const [drgs, setDrgs] = useState<{ id: number, codigo: string, descricao: string }[]>();
	const [medicos, setMedicos] = useState<{ id: number, crm: string, nome: string }[]>();
	const [tiposOrigemPaciente, setTiposOrigemPaciente] = useState<string[]>();
	const [cids, setCids] = useState<{ id: number, codigo: string, descricao: string }[]>();

	const [setores, setSetores] = useState<string[]>();

	const loadContext = () => {
		setLoad(!load);
	}

	// LOAD CONVENIOS
	useEffect(() => {
		if(!token || !unidade) return;
		if(convenios) return;
		const loadStoragedData = async () => {			
			const storedConvenios = localStorage.getItem(CONVENIOS_KEY);

			if (storedConvenios) {
				setConvenios(JSON.parse(storedConvenios));
			} else {
				apiRb.get('/convenios')
					.then(response => response.data)
					.then(result => {
						if(result && result.length > 0) {
							setConvenios(result);
							localStorage.setItem(CONVENIOS_KEY, JSON.stringify(result));
						}
					})
			}
		};
		loadStoragedData();
	}, [load]);

	// LOAD ESPECIALIDADES
	useEffect(() => {
		if(!token || !unidade) return;
		if(especialidades) return;
		const loadStoragedData = async () => {			
			const storedEspecialidades = localStorage.getItem(ESPECIALIDADES_KEY);

			if (storedEspecialidades) {
				setEspecialidades(JSON.parse(storedEspecialidades));
			} else {
				apiRb.get('/especialidades')
					.then(response => response.data)
					.then(result => {
						if(result && result.length > 0) {
							setEspecialidades(result);
							localStorage.setItem(ESPECIALIDADES_KEY, JSON.stringify(result));
						}
					})
			}
		};
		loadStoragedData();
	}, [load]);

	// LOAD PROCEDIMENTOS
	useEffect(() => {
		if(!token || !unidade) return;
		const loadStoragedData = async () => {
			const storedProcedimentos = localStorage.getItem(PROCEDIMENTOS_KEY);

			if (storedProcedimentos) {
				setProcedimentos(JSON.parse(storedProcedimentos));
			} else {
				apiRb.get('/procedimentos')
					.then(response => response.data)
					.then((procedimentos:any) => {
						let procs:any = [];
						procedimentos.forEach((procedimento:any) => {
							procs.push({ id: procedimento.id, CD_PRO_FAT: procedimento.CD_PRO_FAT, nome: procedimento.nome });
						})
						localStorage.setItem(PROCEDIMENTOS_KEY, JSON.stringify(procs));
						setProcedimentos(procs);
					})
			}
		};
		loadStoragedData();
	}, [load]);

	// LOAD PROCEDIMENTOS CIRURGICOS
	useEffect(() => {
		if(!token || !unidade) return;
		const loadStoragedData = async () => {
			const storedProcedimentos = localStorage.getItem(PROCEDIMENTOS_CIRURGICOS_KEY);

			if (storedProcedimentos) {
				setProcedimentosCirurgicos(JSON.parse(storedProcedimentos));
			} else {
				apiRb.get('/procedimentos_cirurgicos')
					.then(response => response.data)
					.then((procedimentos:any) => {
						let procs:any = [];
						procedimentos && procedimentos.forEach((procedimento:any) => {
							procs.push({ codigo: procedimento[0], descricao: procedimento[1] });
						})
						localStorage.setItem(PROCEDIMENTOS_CIRURGICOS_KEY, JSON.stringify(procs));
						setProcedimentosCirurgicos(procs);
					})
			}
		};
		loadStoragedData();
	}, [load]);

	// LOAD DRGS
	useEffect(() => {
		if(!token || !unidade) return;
		const loadStoragedData = async () => {
			const storedDrgs = localStorage.getItem(DRGS_KEY);

			if (storedDrgs) {
				setDrgs(JSON.parse(storedDrgs));
			} else {
				apiRb.get('/drg')
					.then(response => response.data)
					.then((result:any) => {
						let procs:any = [];
						result.forEach((drg:any) => {
							procs.push({ id: drg.id, codigo: drg.codigo, descricao: drg.descricao });
						})
						localStorage.setItem(DRGS_KEY, JSON.stringify(procs));
						setDrgs(procs);
					})
			}
		};
		loadStoragedData();
	}, [load]);

	// LOAD MEDICOS
	useEffect(() => {
		if(!token || !unidade) return;
		const loadStoragedData = async () => {
			const storedMedicos = localStorage.getItem(MEDICOS_KEY);

			if (storedMedicos) {
				setMedicos(JSON.parse(storedMedicos));
			} else {
				apiRb.get('/medicos')
					.then(response => response.data)
					.then((medicos:any) => {
						let meds:any = [];
						medicos.forEach((medico:any) => {
							meds.push({ id: medico.id, crm: medico.crm, nome: medico.nome });
						})
						localStorage.setItem(MEDICOS_KEY, JSON.stringify(meds));
						setMedicos(meds);
					})
			}
		};
		loadStoragedData();
	}, [load]);

	// LOAD TIPOS ORIGEM PACIENTE
	useEffect(() => {
		if(!token || !unidade) return;
		const loadStoragedData = async () => {
			const storedOrigemPaciente = localStorage.getItem(TIPOS_ORIGEM_PACIENTE_KEY);

			if (storedOrigemPaciente) {
				setTiposOrigemPaciente(JSON.parse(storedOrigemPaciente));
			} else {
				apiRb.get('/tipos_origem_paciente')
					.then(response => response.data)
					.then(result => {
						if(result && result.length > 0) {
							setTiposOrigemPaciente(result);
							localStorage.setItem(TIPOS_ORIGEM_PACIENTE_KEY, JSON.stringify(result));
						}
					})
			}
		};
		loadStoragedData();
	}, [load]);

	// LOAD CIDS
	useEffect(() => {
		if(!token || !unidade) return;
		const loadStoragedData = async () => {
			const storedCids = localStorage.getItem(CIDS_KEY);

			if (storedCids) {
				setCids(JSON.parse(storedCids));
			} else {
				apiRb.get('/cids')
					.then(response => response.data)
					.then((data:any) => {
						let arr:any = [];
						data.map((cid:any) => {
							arr.push({id: cid[0], codigo: cid[1], descricao: cid[2], label: `${cid[1] - cid[2]}`});
						})
						localStorage.setItem(CIDS_KEY, JSON.stringify(arr));
						setCids(arr);
					})
			}
		};
		loadStoragedData();
	}, [load]);
	
	// @ts-ignore
	const values: ILocalContextProps = useMemo(
		() => ({
			convenios,
			procedimentos,
			procedimentosCirurgicos,
			setores,
			especialidades,
			drgs,
			medicos,
			tiposOrigemPaciente,
			cids,
			loadContext,
		}),
		[
			convenios,
			procedimentos,
			procedimentosCirurgicos,
			setores,
			especialidades,
			drgs,
			medicos,
			tiposOrigemPaciente,
			cids,
			loadContext,
		],
	);

	return <LocalContext.Provider value={values}>{children}</LocalContext.Provider>;
};
LocalContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default LocalContext;

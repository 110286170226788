import React, { useEffect } from 'react';
import PageWrapper from '../../components/layout/Content/PageWrapper';
import Page from '../../components/layout/Content/Page';
import apiRb from '../../services/apiRb';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';

const ReportDownload = () => {

    const { id } = useParams();
    const reportId = id;
    const { t } = useTranslation(['mensagem']);

    useEffect(() => {
        apiRb.get(`/reports/download/${reportId}`, { responseType: 'blob'})
        .then(response => response.data)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relatorio_atendimentos.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.log(error);
        });
    })

    return (
        <PageWrapper title={"Download"}>
			<Page>
				<div className='row d-flex align-items-center h-90'>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<div className='text-dark fw-bold fs-3'>
							{t('mensagem.download')}
						</div>
                        <p><br/><FaDownload size={'5x'} /></p>
					</div>
				</div>
			</Page>
		</PageWrapper>
    )
}

export default ReportDownload;
export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const toFixedTrunc = (x:number, n:number) => {
	const v = (typeof x === 'string' ? x : x.toString()).split('.');
	if (n <= 0) return v[0];
	let f = v[1] || '';
	if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
	while (f.length < n) f += '0';
	return `${v[0]}.${f}`
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const dateFormat = (date: Date | string | undefined, fullYear?: boolean) => {
    // Verificar se a data é uma string e tentar convertê-la para Date
    if (typeof date === 'string') {
        date = new Date(date);
    }

    // Verificar se a data é um objeto Date válido
    if (date instanceof Date && !isNaN(date.getTime())) {
		if (!fullYear) {
            date.setDate(date.getDate() + 1);
        }
        const options: Intl.DateTimeFormatOptions = {
            year: fullYear ? 'numeric' : '2-digit',
            month: '2-digit',
            day: '2-digit'
        };

        return date.toLocaleDateString('pt-BR', options);
    }

    // Caso a data não seja válida, retornar uma string vazia ou tratar conforme necessário
    return '';
}

export const dateTimeFormat = (date: Date | undefined | string) => {
	if(date !== undefined && date !== null && date instanceof Date) {
		return date.toLocaleDateString('pt-BR') + ' ' + date.toLocaleTimeString('pt-BR');
	} else if(date !== undefined && date !== null) {
		return new Date(date).toLocaleDateString('pt-BR') + ' ' + new Date(date).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
	}
}

export const priceFormat = (price: number, rounded?: boolean) => {
	if(price && price !== undefined && price !== null || price == 0) {
		return price.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
	}
};
export const priceFormatMaxFractionDigits = (price: number, maxFraction: number) => {
	if(price && price !== undefined && price !== null || price == 0) {
		return price.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: maxFraction
		});
	}
};

export const quantityFormatRounded = (qtd: number | undefined, fractionDigits?: number | 0) => {
	if(qtd !== undefined && qtd !== null) {
		return qtd.toLocaleString('pt-BR', {
			style: 'decimal',
			maximumFractionDigits: fractionDigits,
		});
	}
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percentFormat = (value: number) => value.toFixed(0) + '%';

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};
